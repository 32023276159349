<template>
  <h1>Stem op `Herstel Bakhuisje Klein Karelke`</h1>

  <p>
    De stemming is gesloten!<br />
    Hartelijk voor alle stemmen!
  </p>


</template>

<script>

export default {
  name: 'App',
}
</script>

<style>

body {
  color: #000;
  margin: 60px 1rem;
  background: #ebab22;
  font-size: 1.2rem;
  line-height: 1.9rem;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

}

h1 {
  font-size: 2rem;
  margin-bottom: 2rem;
  line-height: 2rem;
}

ol {
  display: inline-block;
  text-align: left;
  font-weight: 600;
}

.btn {
  color: #fff;
  background: #d43776;
  padding: 10px;
  text-decoration: none;
  display: inline-block;
  transition: .2s all;
}

.btn:hover {
  background: #ae295e;
}

</style>
